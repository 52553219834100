import gql from 'graphql-tag';

export default gql`
query getGroupSlugByGroupId($groupId: Int!) {
  groups(where: {group_id: {_eq: $groupId}}) {
    domain
    name
    slug
    language_id
    group_path
    settings
    pirsch_code
  }
}
`;
