<template>
  <div class="dashboard-container">
    <div class="table-search d-flex py-5 justify-content-end">
      <b-input-group class="search-group d-flex align-items-center mr-2">
        <b-icon
          icon="search"
          aria-hidden="true"
          scale="1"
        ></b-icon>
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          @input="searchFieldTriggered"
          :placeholder="$t('webmag.search')"
        ></b-form-input>
      </b-input-group>
      <b-button
        class="mr-2 dark-grey-buttons"
        :class="{active: !isTableView }"
        @click="changeDashboardView('cards')"
      >
        <b-icon
          icon="grid3x3-gap-fill"
          aria-hidden="true"
          class="top--1"
          font-scale="1.1"
          scale="1.1"
        ></b-icon>
      </b-button>
      <b-button
        class="dark-grey-buttons"
        :class="{active: isTableView }"
        @click="changeDashboardView('table')"
      >
        <b-icon
          icon="list-task"
          aria-hidden="true"
          class="top--1"
          font-scale="1.1"
          scale="1.1"
        ></b-icon>
      </b-button>
    </div>
    <b-table
      v-if="showItemsTable"
      :fields="fields"
      :items="projectAndFolderItems"
      :filter="filter"
      :filter-included-fields="filterOn"
      show-empty
      :empty-filtered-html="$t('webmag.emptyFilteredText')"
      borderless
      dark
      fixed
      @row-clicked="navigateToItem"
      :table-class="{ 'table-view': isTableView, 'card-view': !isTableView}"
    >
      <template #table-colgroup="scope" v-if="isTableView">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.width }"
        >
      </template>
      <template #cell(image)="data">
        <div
          v-if="data.item.type === 'folder'"
          class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
        >
          <b-icon
            icon="folder"
            aria-hidden="true"
            scale="1"
          ></b-icon>
        </div>
        <div
          v-if="data.item.type === 'project'"
          class="cell-background-image d-flex justify-content-center align-items-center"
        >
          <b-icon
            icon="files"
            aria-hidden="true"
            scale="1"
          ></b-icon>
        </div>
      </template>
      <template #cell(name)="data">
          <span :id="`tooltip-button-variant-${data.item.slug}`">
            {{ data.item.name | sliceStingPos30 }}
          </span>
        <b-tooltip
          v-if="data.item.name && data.item.name.length > 30"
          :target="`tooltip-button-variant-${data.item.slug}`"
        >
          {{ data.item.name }}
        </b-tooltip>
      </template>
      <template #cell(path)="data">
          <span :id="`tooltip-breadcrumb-${data.item.slug}`">
            {{ data.item.path | sliceStingPos30 }}
          </span>
        <b-tooltip
          v-if="data.item.path && data.item.path.length > 30"
          :target="`tooltip-breadcrumb-${data.item.slug}`"
        >
          {{ data.item.path }}
        </b-tooltip>
      </template>
      <template #cell(tags)="data">
        <ul v-if="data.item.tags" class="d-flex">
          <li
            v-for="(item, index) in data.item.tags"
            :key="'tag-' + index">
            {{ item }}
          </li>
        </ul>
      </template>
      <template #cell(status)="data">
        <div class="d-flex justify-content-center" v-if="data.item.type !== 'folder'">
          <publish-status-icons
            :item="data.item"
            id="dashboard"
            :key="`publishIcon-${data.item.id}-${counter}`"
          ></publish-status-icons>
          <searchable-status-icons
            :item="data.item"
            id="dashboard"
          ></searchable-status-icons>
          <status-icons
            :item="data.item"
            id="dashboard"
          ></status-icons>
          <listed-status-icons
            :item="data.item"
            id="dashboard"
          ></listed-status-icons>
        </div>
      </template>
      <template #cell(dot)="data">
        <dot-dropdown
          v-if="isTableView && data.item.type === 'project'"
          :item="data.item"
          :group-slug="groupSlug"
          :is-team-view="true"
          :parent-item="currentItem"
          @item-action="itemAction"
        />
      </template>
      <template #cell(cards)="data">
        <dashboard-item
          v-if="!isTableView"
          :item="data.item"
          :group-slug="groupSlug"
          :parent-item="currentItem"
          :slug-path="data.item.path"
          :is-team-view="true"
          @item-action="itemAction"
          :showDotMenu="data.item.type === 'project'"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import DashboardItem from '@/components/dashboard/DashboardItem.vue';
import DotDropdown from '@/components/dashboard/DotDropdown.vue';
import ListedStatusIcons from '@/components/dashboard/ListedStatusIcons.vue';
import PublishStatusIcons from '@/components/dashboard/PublishStatusIcons.vue';
import SearchableStatusIcons from '@/components/dashboard/SearchableStatusIcons.vue';
import StatusIcons from '@/components/dashboard/StatusIcons.vue';
import SetPublishStateOfPageById from '@/graphQlQueries/mutations/setPublishStateOfPageById';
import SetPublishStateOfProject from '@/graphQlQueries/mutations/setPublishStateOfProject';
import UpdatePagePublishFlag from '@/graphQlQueries/mutations/updatePagePublishFlag';
import UpdatePageVersionsPublishState from '@/graphQlQueries/mutations/updatePageVersionsPublishState';
import GetAllAvailableLanguages from '@/graphQlQueries/queries/getAllAvailableLanguages';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import GetAllChildPagesOfProject from '@/graphQlQueries/queries/getAllChildPagesOfProject';
import GetAllSlugPathsOfTeamItems from '@/graphQlQueries/queries/getAllSlugPathsOfTeamItems';
import GetGroupSlugByGroupId from '@/graphQlQueries/queries/getGroupSlugByGroupId';
import GetPageDataToPublish from '@/graphQlQueries/queries/getPageDataToPublish';
import GetProjectWithChildPagesDataToPublish from '@/graphQlQueries/queries/getProjectWithChildPagesDataToPublish';
import SendPublishedDataToServer from '@/mixins/sendPublishedDataToServer';

const dateFormat = (jsDateString) => {
  const date = new Date(jsDateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const fullYear = date.getFullYear();

  return `${day <= 9 ? `0${day}` : day}.${month <= 9 ? `0${month}` : month}.${fullYear}`;
};

export default {
  name: 'TeamDashboard',
  components: {
    SearchableStatusIcons,
    StatusIcons,
    ListedStatusIcons,
    DotDropdown,
    PublishStatusIcons,
    DashboardItem,
  },
  mixins: [SendPublishedDataToServer],
  props: ['teamIds', 'groupId', 'teamsItems', 'userId'],
  data: () => ({
    projectAndFolderItems: [],
    projectIds: [],
    folderIds: [],
    groupDomainQuery: null,
    groupDomain: null,
    groupSlug: null,
    allSlugsOfItems: null,
    runGetSlugsQuery: false,
    showItemsTable: false,
    isTableView: true,
    filter: null,
    queryChildPagesOfProject: null,
    childPageIdsOfProject: null,
    doPublishTheSelectedItem: false,
    selectedItemForAction: null,
    allAvailableLanguages: null,
    filterOn: ['name', 'tags', 'path'],
    currentItem: null, // todo: has to be changed
    counter: Math.floor(Math.random() * 1000),
    fields: [
      {
        key: 'image',
        label: '',
        sortable: false,
        width: '50px',
      },
      {
        key: 'name',
        label: 'Name',
        sortable: true,
        width: 'auto',
      },
      {
        key: 'path',
        label: 'Path',
        sortable: true,
        width: 'auto',
      },
      {
        key: 'tags',
        label: 'Tags',
        sortable: false,
        width: '100px',
      },
      {
        key: 'lastUpdated',
        label: 'Last Updated',
        sortable: true,
        width: '120px',
      },
      {
        key: 'created',
        label: 'Created',
        sortable: true,
        width: '120px',
      },
      {
        key: 'status',
        label: 'Status',
        sortable: false,
        width: '75px',
      },
      {
        key: 'dot',
        label: '',
        sortable: false,
        width: '40px',
      },
      {
        key: 'cards',
        label: '',
        sortable: false,
        width: '0',
      },
    ],
  }),
  filters: {
    formatOrder(value) {
      return (value < 10) ? `0${value}.` : `${value}.`;
    },
    sliceStingPos30(value) {
      return (value && value.length > 30) ? `${value.slice(0, 30)}...` : value;
    },
  },
  created() {
    this.isTableView = this.$localStorage.get('isDashboardTableView');
  },
  apollo: {
    groupDomainQuery: {
      query: GetGroupSlugByGroupId,
      variables() {
        return {
          groupId: this.groupId,
        };
      },
      update(data) {
        this.groupDomain = (data.groups[0]?.domain) ? data.groups[0].domain : null;
        this.groupSlug = (data.groups[0]?.slug) ? data.groups[0].slug : null;
        this.prepareAllItemsOfTeamsForDisplay(this.teamsItems);
      },
      skip() {
        return !this.groupId;
      },
    },
    allAvailableLanguages: {
      query: GetAllAvailableLanguages,
      update(data) {
        return data.languages;
      },
    },
    allSlugsOfItems: {
      query: GetAllSlugPathsOfTeamItems,
      variables() {
        return {
          folderIds: this.folderIds,
          projectIds: this.projectIds,
        };
      },
      update(data) {
        console.log('data', data);
        data.item_lookup.forEach((item) => {
          if (item.folder_id) {
            const folderItemRef = this.projectAndFolderItems.find(
              (element) => element.folderId === item.folder_id,
            );
            // we use the reference here
            folderItemRef.path = item.slug_path;
          }
          if (item.project_id) {
            // lookup the item with the folder id
            const projectItemRef = this.projectAndFolderItems.find(
              (element) => element.projectId === item.project_id,
            );
            projectItemRef.path = item.slug_path;
          }
        });
        this.showItemsTable = true;
      },
      skip() {
        return !this.runGetSlugsQuery;
      },
    },
    dataFromTheDBForPublishing: {
      query() {
        return (this.selectedItemForAction.type === 'project')
          ? GetProjectWithChildPagesDataToPublish
          : GetPageDataToPublish;
      },
      variables() {
        return (this.selectedItemForAction.type === 'project')
          ? { projectId: this.selectedItemForAction.id }
          : { pageId: this.selectedItemForAction.id };
      },
      update(data) {
        // reset the publish variable to set the query on hold
        this.doPublishTheSelectedItem = false;
        // call the publish data method here so we can send
        // the data from the database to the server
        const publishData = (this.selectedItemForAction.type === 'project')
          ? data.projects_by_pk
          : data.pages_by_pk;
        this.publishItem(publishData);
      },
      skip() {
        // run this query only when we set the 'doPublishTheSelectedItem' variable
        return !this.doPublishTheSelectedItem || !this.selectedItemForAction || this.selectedItemForAction.type === 'folder';
      },
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    teamsItems() {
      this.prepareAllItemsOfTeamsForDisplay();
    },
  },
  methods: {
    prepareAllItemsOfTeamsForDisplay() {
      const itemsArray = [];
      this.projectIds = [];
      this.projectAndFolderItems = [];
      console.log('this teams', this.teamsItems);
      this.teamsItems.forEach((teamsItem) => {
        if (teamsItem.folder) {
          const itemObject = {
            id: teamsItem.folder.folder_id,
            type: 'folder',
            name: teamsItem.folder.name,
            published: null,
            order: teamsItem.folder.order_index,
            group_id: this.groupId,
            slug: teamsItem.folder.slug,
            folderId: teamsItem.folder.folder_id,
            projectId: null,
            pageId: null,
            tags: null,
            image: null,
            lastUpdated: dateFormat(teamsItem.folder.updated_at),
            created: dateFormat(teamsItem.folder.created_at),
            groupDomain: this.groupDomain,
            childPages: null,
            breadcrumbs:
              this.prepareTheCardBreadcrumb(teamsItem.folder.lookup_entries[0].breadcrumbs),
          };
          this.projectAndFolderItems.push(itemObject);
          this.folderIds.push(itemObject.id);
          itemsArray.push(itemObject);
        } else {
          const itemObject = {
            id: teamsItem.project.project_id,
            type: 'project',
            name: teamsItem.project.name,
            published: teamsItem.project.published,
            order: teamsItem.project.order_index,
            group_id: this.groupId,
            slug: teamsItem.project.slug,
            projectId: teamsItem.project.project_id,
            pageId: null,
            searchable: teamsItem.project.searchable,
            listed: teamsItem.project.listed,
            image: null,
            tags: this.getAllTags(teamsItem.project, 'project'),
            lastUpdated: dateFormat(teamsItem.project.updated_at),
            created: dateFormat(teamsItem.project.created_at),
            groupDomain: this.groupDomain,
            childPages: teamsItem.project.child_pages,
            breadcrumbs:
              this.prepareTheCardBreadcrumb(teamsItem.project.lookup_entries[0].breadcrumbs),
          };
          itemsArray.push(itemObject);
          this.projectIds.push(itemObject.id);
          this.projectAndFolderItems.push(itemObject);
        }
      });
      this.runGetSlugsQuery = true;
    },
    prepareTheCardBreadcrumb(cardBreadcrumbs) {
      let breadcrumbString = '';
      cardBreadcrumbs.forEach((breadcrumb, index) => {
        if (!breadcrumb.root && index < cardBreadcrumbs.length - 1) {
          breadcrumbString += breadcrumb.name;
        }
        if (!breadcrumb.root && index < cardBreadcrumbs.length - 2) {
          breadcrumbString += ' > ';
        }
      });
      return breadcrumbString;
    },
    searchFieldTriggered(value) {
      this.pageTableIsFiltered = value !== '';
    },
    changeDashboardView(view) {
      if (view === 'table') {
        this.isTableView = true;
        this.$localStorage.set('isDashboardTableView', true);
      }
      if (view === 'cards') {
        this.isTableView = false;
        this.pageTableIsSorted = false;
        this.pageTableKey++;
        if (this.$refs.pageTable) {
          this.$refs.pageTable.refresh();
        }
        this.$localStorage.set('isDashboardTableView', false);
      }
    },
    getAllTags(item, itemType) {
      const allTags = [];
      if (itemType === 'project') {
        if (item.projects_tags.length > 0) {
          item.projects_tags.forEach((tagItem) => {
            if (!allTags.includes(tagItem.tag.name)) {
              allTags.push(tagItem.tag.name);
            }
          });
          return allTags;
        }
      }
      return null;
    },
    navigateToItem(item) {
      this.$router.push(item.path);
    },
    // this action is responsible for the dotdotdot menu
    itemAction(actionType, item) {
      // set the item to the selected item
      this.selectedItemForAction = { ...item };
      this.selectedItemForAction.parent_slug_path = this.$route.fullPath;

      switch (actionType) {
        case 'publish':
          this.publishTriggeredFromDotDropdown = true;
          // set the variable to run the query!
          this.doPublishTheSelectedItem = true;
          break;
        case 'unPublish':
          this.unpublishItem();
          break;
        default:
          console.log('action Type data', actionType, item);
      }
    },
    async unpublishItem() {
      let actionName = null;
      let childPagesOfProject = null;
      let result = null;
      // eslint-disable-next-line default-case
      switch (this.selectedItemForAction.type) {
        case 'project':
          // prepare the data object
          actionName = 'unpublishProject';
          // get the childPagesOfProject
          result = await this.$apollo.query({
            query: GetAllChildPagesOfProject,
            variables: {
              projectId: this.selectedItemForAction.id,
            },
            fetchPolicy: 'network-only',
          });
          childPagesOfProject = (result?.data.projects_by_pk.child_pages)
            ? result.data.projects_by_pk.child_pages
            : null;
          break;
        case 'page':
          // prepare the data object
          actionName = 'unpublishPage';
          break;
      }
      try {
        const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
          action: actionName,
          data: {
            slug: this.selectedItemForAction.slug,
            parent_slug_path: this.selectedItemForAction.parent_slug_path,
            group_slug: this.groupSlug,
            group_domain: this.selectedItemForAction.groupDomain,
            child_pages: childPagesOfProject,
          },
        });
        if (publishedDataWasSuccessfulSend) {
          await this.setThePublishStateOfProjectOrPageInDB(false);
          await this.refreshItemsQuery();
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    async refreshItemsQuery() {
      // the counter is used for the keys of the dashboard items to force them to rerender
      this.counter = this.counter + 1000;
      this.$emit('refresh-items-query');
    },
    async setThePublishStateOfProjectOrPageInDB(published) {
      const mutationQuery = (this.selectedItemForAction.type === 'project')
        ? SetPublishStateOfProject
        : SetPublishStateOfPageById;
      await this.$apollo.mutate({
        mutation: mutationQuery,
        variables: {
          id: this.selectedItemForAction.id,
          published,
        },
      });
      if (this.selectedItemForAction.type === 'project') {
        // publish only published items
        const result = await this.$apollo.query({
          query: GetAllChildPageIdsOfProject,
          variables: {
            projectId: this.selectedItemForAction.id,
          },
        });
        let childPageIdsOfProject = this.getChildPageIdsOfDBData(result.data);
        if (childPageIdsOfProject) {
          // check also fot the published elements
          if (this.selectedItemForAction.statusChanged === true) {
            childPageIdsOfProject = this.publishedChildPageIdsOfDB;
          }
          await this.$apollo.mutate({
            mutation: UpdatePagePublishFlag,
            variables: {
              pageIds: childPageIdsOfProject,
              isPublished: published,
            },
          });
        }
      }
    },
    getChildPageIdsOfDBData(data) {
      const projectIdsArray = [];
      this.publishedChildPageIdsOfDB = [];
      if (data.projects[0]?.child_pages) {
        const childPages = data.projects[0].child_pages;
        childPages.forEach((childPage) => {
          if (childPage.page_id) {
            projectIdsArray.push(childPage.page_id);
            if (childPage.page_id && childPage.published === true) {
              this.publishedChildPageIdsOfDB.push(childPage.page_id);
            }
          }
        });
      }
      return (projectIdsArray.length)
        ? projectIdsArray
        : null;
    },
    async setThePublishStateOfPageVersions(pageVersionIds, publishStatus) {
      await this.$apollo.mutate({
        mutation: UpdatePageVersionsPublishState,
        variables: {
          pageVersionIds,
          publishStatus,
          userId: this.userId,
        },
      });
    },
    async publishItem(data) {
      if (data) {
        const dataToBeSendToTheServer = data;
        let actionName = null;
        let languageId = null;
        let localNameObject = null;
        const allPageVersionIdsOfPublishedData = [];
        switch (this.selectedItemForAction.type) {
          case 'project':
            // prepare the data object
            actionName = 'publishProject';
            // set the flag for publish to be true because we send data
            // first to PHP API and then write in the DB
            // and also for all child items
            dataToBeSendToTheServer.published = true;
            languageId = dataToBeSendToTheServer.language_id;
            localNameObject = this.allAvailableLanguages.find(
              (languageItem) => languageItem.language_id === languageId,
            );
            dataToBeSendToTheServer.language = (localNameObject)
              ? localNameObject.name
              : null;
            for (let i = 0; i < dataToBeSendToTheServer.child_pages.length; i++) {
              languageId = dataToBeSendToTheServer.child_pages[i].language_id;
              localNameObject = this.allAvailableLanguages.find(
                // eslint-disable-next-line no-loop-func
                (languageItem) => languageItem.language_id === languageId,
              );
              dataToBeSendToTheServer.child_pages[i].language = (localNameObject)
                ? localNameObject.name
                : null;
              const publishChanges = !!(this.selectedItemForAction.statusChanged);
              if (publishChanges) {
                // only republish the already published pages
                if (dataToBeSendToTheServer.child_pages[i].published === true) {
                  dataToBeSendToTheServer.child_pages[i]
                    .latest_page_version.published = true;
                  allPageVersionIdsOfPublishedData.push(
                    dataToBeSendToTheServer
                      .child_pages[i]
                      .latest_page_version
                      .page_version_id,
                  );
                }
              } else {
                // publish all pages of project
                dataToBeSendToTheServer.child_pages[i].published = true;
                dataToBeSendToTheServer.child_pages[i]
                  .latest_page_version.published = true;
                allPageVersionIdsOfPublishedData.push(
                  dataToBeSendToTheServer
                    .child_pages[i]
                    .latest_page_version
                    .page_version_id,
                );
              }
            }
            break;
          case 'page':
            // prepare the data object
            actionName = 'publishPage';
            // set the flag for publish to be true because we send data
            // first to PHP API and then write in the DB
            dataToBeSendToTheServer.published = true;
            // publish also page versions
            dataToBeSendToTheServer.latest_page_version.published = true;
            allPageVersionIdsOfPublishedData.push(
              dataToBeSendToTheServer.latest_page_version.page_version_id,
            );
            if (dataToBeSendToTheServer.parent_folder?.corporate_identity) {
              dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
                dataToBeSendToTheServer.parent_folder.corporate_identity,
              ));
              delete dataToBeSendToTheServer.parent_folder;
            } else if (dataToBeSendToTheServer.parent_project?.corporate_identity) {
              dataToBeSendToTheServer.corporate_identity = JSON.parse(JSON.stringify(
                dataToBeSendToTheServer.parent_project.corporate_identity,
              ));
              delete dataToBeSendToTheServer.parent_project;
            }
            // get the name of the language by the language id
            languageId = dataToBeSendToTheServer.language_id;
            localNameObject = this.allAvailableLanguages.find(
              (languageItem) => languageItem.language_id === languageId,
            );
            dataToBeSendToTheServer.languages = (localNameObject)
              ? localNameObject.name
              : null;
            break;
          default:
            console.log(this.selectedItemForAction);
        }
        try {
          // check if project is published from header or dot dropdown
          const parent_slug_path = (this.publishTriggeredFromDotDropdown)
            ? this.selectedItemForAction.parent_slug_path
            : this.parentSlugPath;
          const publishedDataWasSuccessfulSend = await this.sendPublishedDataToServer({
            action: actionName,
            data: {
              parent_slug_path,
              group_slug: this.groupSlug,
              ...dataToBeSendToTheServer,
            },
          });
          if (publishedDataWasSuccessfulSend) {
            await this.setThePublishStateOfProjectOrPageInDB(true);
            // set the publish state of all page versions!!!
            await this.setThePublishStateOfPageVersions(
              allPageVersionIdsOfPublishedData,
              true,
            );
            // after publishing open the success modal!
            const projectWasPublished = (this.selectedItemForAction.type === 'project');
            if (this.publishTriggeredFromDotDropdown || projectWasPublished) {
              // check if project published from header or from dot dropdown
              if (!this.selectedItemForAction.slug) {
                this.selectedItemForAction.slug = this.slugOfPage;
                this.selectedItemForAction.parent_slug_path = this.parentSlugPath;
                this.selectedItemForAction.groupDomain = this.groupDomain;
              }
              const slugPath = (this.selectedItemForAction.parent_slug_path === '/')
                ? `/${this.selectedItemForAction.slug}`
                : `${this.selectedItemForAction.parent_slug_path}/${this.selectedItemForAction.slug}`;
              this.publishedItem = {
                slug: this.selectedItemForAction.slug,
                type: this.selectedItemForAction.type,
                groupDomain: this.selectedItemForAction.groupDomain,
                slugPath,
              };
              this.showPublishSuccessModal = true;
              this.publishTriggeredFromDotDropdown = false;
            }
            await this.refreshItemsQuery();
          }
        } catch (ex) {
          console.log(ex);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.draggable-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.table-dark {
  background-color: transparent;
}

/deep/ table.table-view {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;

    &:nth-child(7) {
      text-align: center;
    }
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &:first-child {
        padding: 0;
      }

      &:nth-child(3) {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            color: #44484C;
            background-color: #B9B9B9;
            text-transform: uppercase;
          }
        }
      }

      &:nth-child(7) {
        text-align: center;
      }

      &:nth-child(8) {
        padding: 0;

        button {
          width: 40px;
          min-height: 36px;
        }
      }

      &:last-child {
        xdisplay: none;
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  padding: 5px;

  &:hover {
    color: #fff;
  }
}

/deep/ table {
  tr {
    outline: none;
    cursor: pointer;
  }
}

/deep/ .table-view .btn.dot-button {
  padding: 0;
  line-height: 1;

  text-align: center;
  vertical-align: text-top;
}

/deep/ table.card-view {
  thead {
    display: none;
  }

  tbody {
    display: flex;
    flex-wrap: wrap;

    tr > td {
      display: none;

      &:last-child {
        display: block;
        padding: 0;
      }
    }
  }
}

.search-group {
  width: 200px;
  background-color: #53575B;
  padding-left: 11px;
}

#filter-input, #filter-input:focus {
  color: #b9b9b9;
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

#filter-input::-webkit-search-cancel-button {
  appearance: none;
  height: 23px;
  width: 20px;
  background-size: 21px;
  background: url('../../assets/svg/icon-search-cancel.svg') no-repeat center center;
  cursor: pointer;
}

button.btn.active svg {
  color: #5cb85c;
}
</style>
