import axios from 'axios';

/**
 * sends the data object to the server
 *
 * @param data
 * {
 *   action: 'action-name',
 *   data: {
 *     // publish-data
 *   }
 * }
 */
const sendPublishedDataToServer = {
  methods: {
    async sendPublishedDataToServer(data) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_PHP_SERVER_ENDPOINT}/wps/api_php/publishedData.php`,
          data,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json',
            },
          },
        );
        console.log('RESPONSE', response.data);
        return true;
      } catch (error) {
        console.error('Publish Data to server failed', error);
        return false;
      }
    },
  },
};

export default sendPublishedDataToServer;
