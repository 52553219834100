import gql from 'graphql-tag';

export default gql`
query getAllSlugPathsOfTeamItems($folderIds: [Int!], $projectIds: [Int!]) {
  item_lookup(where: {
    _or: [
      {folder_id: { _in: $folderIds}},
      {project_id: { _in: $projectIds}}
    ]
  }) {
    folder_id
    project_id
    slug_path
    item_path
  }
}
`;
