var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-container" },
    [
      _c(
        "div",
        { staticClass: "table-search d-flex py-5 justify-content-end" },
        [
          _c(
            "b-input-group",
            { staticClass: "search-group d-flex align-items-center mr-2" },
            [
              _c("b-icon", {
                attrs: { icon: "search", "aria-hidden": "true", scale: "1" }
              }),
              _c("b-form-input", {
                attrs: {
                  id: "filter-input",
                  type: "search",
                  placeholder: _vm.$t("webmag.search")
                },
                on: { input: _vm.searchFieldTriggered },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "mr-2 dark-grey-buttons",
              class: { active: !_vm.isTableView },
              on: {
                click: function($event) {
                  return _vm.changeDashboardView("cards")
                }
              }
            },
            [
              _c("b-icon", {
                staticClass: "top--1",
                attrs: {
                  icon: "grid3x3-gap-fill",
                  "aria-hidden": "true",
                  "font-scale": "1.1",
                  scale: "1.1"
                }
              })
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "dark-grey-buttons",
              class: { active: _vm.isTableView },
              on: {
                click: function($event) {
                  return _vm.changeDashboardView("table")
                }
              }
            },
            [
              _c("b-icon", {
                staticClass: "top--1",
                attrs: {
                  icon: "list-task",
                  "aria-hidden": "true",
                  "font-scale": "1.1",
                  scale: "1.1"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.showItemsTable
        ? _c("b-table", {
            attrs: {
              fields: _vm.fields,
              items: _vm.projectAndFolderItems,
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
              "show-empty": "",
              "empty-filtered-html": _vm.$t("webmag.emptyFilteredText"),
              borderless: "",
              dark: "",
              fixed: "",
              "table-class": {
                "table-view": _vm.isTableView,
                "card-view": !_vm.isTableView
              }
            },
            on: { "row-clicked": _vm.navigateToItem },
            scopedSlots: _vm._u(
              [
                _vm.isTableView
                  ? {
                      key: "table-colgroup",
                      fn: function(scope) {
                        return _vm._l(scope.fields, function(field) {
                          return _c("col", {
                            key: field.key,
                            style: { width: field.width }
                          })
                        })
                      }
                    }
                  : null,
                {
                  key: "cell(image)",
                  fn: function(data) {
                    return [
                      data.item.type === "folder"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "folder",
                                  "aria-hidden": "true",
                                  scale: "1"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      data.item.type === "project"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "cell-background-image d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "files",
                                  "aria-hidden": "true",
                                  scale: "1"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(name)",
                  fn: function(data) {
                    return [
                      _c(
                        "span",
                        {
                          attrs: {
                            id: `tooltip-button-variant-${data.item.slug}`
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("sliceStingPos30")(data.item.name)
                              ) +
                              " "
                          )
                        ]
                      ),
                      data.item.name && data.item.name.length > 30
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: `tooltip-button-variant-${data.item.slug}`
                              }
                            },
                            [_vm._v(" " + _vm._s(data.item.name) + " ")]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(path)",
                  fn: function(data) {
                    return [
                      _c(
                        "span",
                        {
                          attrs: { id: `tooltip-breadcrumb-${data.item.slug}` }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("sliceStingPos30")(data.item.path)
                              ) +
                              " "
                          )
                        ]
                      ),
                      data.item.path && data.item.path.length > 30
                        ? _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: `tooltip-breadcrumb-${data.item.slug}`
                              }
                            },
                            [_vm._v(" " + _vm._s(data.item.path) + " ")]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(tags)",
                  fn: function(data) {
                    return [
                      data.item.tags
                        ? _c(
                            "ul",
                            { staticClass: "d-flex" },
                            _vm._l(data.item.tags, function(item, index) {
                              return _c("li", { key: "tag-" + index }, [
                                _vm._v(" " + _vm._s(item) + " ")
                              ])
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(status)",
                  fn: function(data) {
                    return [
                      data.item.type !== "folder"
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c("publish-status-icons", {
                                key: `publishIcon-${data.item.id}-${_vm.counter}`,
                                attrs: { item: data.item, id: "dashboard" }
                              }),
                              _c("searchable-status-icons", {
                                attrs: { item: data.item, id: "dashboard" }
                              }),
                              _c("status-icons", {
                                attrs: { item: data.item, id: "dashboard" }
                              }),
                              _c("listed-status-icons", {
                                attrs: { item: data.item, id: "dashboard" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(dot)",
                  fn: function(data) {
                    return [
                      _vm.isTableView && data.item.type === "project"
                        ? _c("dot-dropdown", {
                            attrs: {
                              item: data.item,
                              "group-slug": _vm.groupSlug,
                              "is-team-view": true,
                              "parent-item": _vm.currentItem
                            },
                            on: { "item-action": _vm.itemAction }
                          })
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "cell(cards)",
                  fn: function(data) {
                    return [
                      !_vm.isTableView
                        ? _c("dashboard-item", {
                            attrs: {
                              item: data.item,
                              "group-slug": _vm.groupSlug,
                              "parent-item": _vm.currentItem,
                              "slug-path": data.item.path,
                              "is-team-view": true,
                              showDotMenu: data.item.type === "project"
                            },
                            on: { "item-action": _vm.itemAction }
                          })
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }